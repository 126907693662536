$(document).ready(function(){
    OpenFullReviews();
    YandexMap();
    SlidersRooms();
    ScrollingUp();
    MobileMenu();
    SmoothAnchors();
    MoreAboutRoom();
    HoverImage();
});

$(window).resize(function(){
    OpenFullReviews();
    MobileMenu();
});


// Карта на блоке контактов
function YandexMap(){
    ymaps.ready(function(){
        // Центра карты
        myMap = new ymaps.Map("map", {
            center: [45.04608496, 41.96734489],
            zoom: 17,
            controls: []
        });
        myMap.behaviors.disable('scrollZoom');

        // Маркер
        var myPlacemark = new ymaps.Placemark([45.04629, 41.9679], {}, {
            iconLayout: 'default#image',
            iconImageHref: '/images/placemark.png',
            iconImageSize: [30, 42],
            iconImageOffset: [-3, -42]
        });
        myMap.geoObjects.add(myPlacemark);
    });
}


// Открытие полного отзыва
function OpenFullReviews(){
     var zIndex = 1;
     $('.reviews__item').click(function(){
          $(this).toggleClass('open');
          $(this).css('z-index', zIndex);
          if($(this).hasClass('open') == false){
               $(this).find('.reviews__item-inside').css('height',  $(this).height());
          }
          else if($(this).hasClass('open') == true){
               $('.reviews__item-inside').each(function(){
                    $(this).css('height', $(this).parent().height());
               });
               var Height = $(this).find('.reviews__item-inside').find('.content .holder').height() + 16 * 7;
               $(this).find('.reviews__item-inside').css('height',  Height);
          }
          zIndex++;
     });
}


// Слайдеры на странице номеров
function SlidersRooms(){
    $('.rooms__content .rooms__item .images .images-list.swiper-container-horizontal').each(function(index, item){
        var swiper = new Swiper(this, {
            grabCursor: false,
            speed: 700,
            keyboardControl: true,
            slidesPerView: 1,
            prevButton:$(this).parent().find('.images-nav .images-nav-button.left'),
            nextButton:$(this).parent().find('.images-nav .images-nav-button.right'),
        });
    });
}


// Слайдеры на странице номеров 2.0
function SlidersRooms2(){

}


// Вернуться наверх стрнаицы
function ScrollingUp(){
    $('#top').off().on('click', function(){
         $('body').animatescroll({
              scrollSpeed: 1500,
              easing: 'easeInOutCubic'
         });
    });
}


// Мобильне меню
function MobileMenu(){
    $('.mobile-menu-button').click(function(){
        $('.header .header__menu').toggleClass('open');
        $('.header .header__menu .menu__link').click(function(){
            $('.header .header__menu').removeClass('open');
        });
    });
}


// Плавные якоря
function SmoothAnchors(){
    $('.header__menu').on('click','a', function (event){
        event.preventDefault();
        var id  = $(this).attr('href'),
            top = $(id).offset().top - $('.header').height();
        $('body, html').animate({scrollTop: top}, 1000);
    });
}


// Переворот карточек с номерами
function MoreAboutRoom(){
     $('.information__read-more .link-more').click(function(){
          $('.rooms__item').removeClass('rotate');
          $(this).parents('.rooms__item').addClass('rotate');
     });

     $('.rooms__content .rooms__item .flipper .back .button-close').click(function(){
          $(this).parents('.rooms__item').removeClass('rotate');
     });
}


function HoverImage(){
    $('.rooms-2__item .images .images-navigation .navigation__item').mouseover(function(){
        var ImageUrl = $(this).attr('data-image');
        $(this).parents('.images').find('.images-holder .image').css('background-image', ImageUrl);
    });
}
